<template>
    <defaultSec class="chat-record" :title="'聊天记录管理'" :isDisableScroll="true" :isFlexSection="true">
        <div class="flex-row-wrap">
            <el-scrollbar class="flex-row-left">
                <div class="left-tab" @click="tabChange(0)" :class="activeTab==0?'left-tab-on':''">聊天统计</div>
                <div class="left-tab" @click="tabChange(1)" :class="activeTab==1?'left-tab-on':''">表格汇总</div>
                <div class="left-tab" @click="tabChange(2)" :class="activeTab==2?'left-tab-on':''">搜索聊天</div>
            </el-scrollbar>
            <el-scrollbar v-show="activeTab==1" class="flex-row-right">
                <div>
                    <div class="search-content">
                        <el-form :inline="true" size="mini" class="demo-form-inline">
                            <el-form-item label="选择时间:">
                                <el-date-picker
                                    size="mini"
                                    v-model="tableParam.date"
                                    value-format="yyyy-MM-dd"
                                    type="daterange"
                                    align="left"
                                    unlink-panels
                                    range-separator="~"
                                    start-placeholder="start"
                                    end-placeholder="end"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-select style="width: 100%" size="small" multiple v-model="tableParam.qyWeixinUserid" placeholder="请选择客服">
                                    <el-option v-for="item in customerList" :value="item.id" :label="item.name"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="danger" @click="initTableList">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <tablePagination :total="total" :pageSize="pageSize" :hidePage="true">
                        <h3 style="text-align: center"><span style="color: #2dacd1">{{tableParam.date[0]}}~{{tableParam.date[1]}}</span> 聊天记录汇总</h3>
                        <el-table :data="tableList" border size="mini"  v-loading="loading">
                            <el-table-column  type="index" label="序号"></el-table-column>
                            <el-table-column  prop="userName" label="客服昵称"></el-table-column>
                            <el-table-column sortable prop="chatCount" label="聊天总数"></el-table-column>
                            <el-table-column sortable prop="chatUserCount" label="聊天总用户数"></el-table-column>
                            <el-table-column sortable prop="qwToUserCount" label="客服发送消息数"></el-table-column>
                            <el-table-column sortable prop="UserToQwCount" label="用户发送消息数"></el-table-column>
                            <el-table-column fixed="right" label="操作" width="100px">
                                <template slot-scope="scope">
                                    <el-button type="text" plain size="mini" @click="showDetail(scope.row)">查看聊天</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </tablePagination>
                </div>
            </el-scrollbar>
            <el-scrollbar class="flex-row-right" v-show="activeTab==0">
                <div class="search-content">
                    <el-form :inline="true" size="mini" class="demo-form-inline">
                        <el-form-item label="汇总:">
                            <el-radio-group v-model="formParam.group">
                                <el-radio-button :label="1">汇总</el-radio-button>
                                <el-radio-button :label="2">明细</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="分组:">
                            <el-radio-group v-model="formParam.byHour">
                                <el-radio-button :label="0">按天</el-radio-button>
                                <el-radio-button :label="1">按时</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker
                                size="mini"
                                v-model="formParam.date"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                align="left"
                                unlink-panels
                                range-separator="~"
                                start-placeholder="start"
                                end-placeholder="end"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-select style="width: 100%" size="small" multiple v-model="formParam.qyWeixinUserid" placeholder="请选择客服">
                                <el-option v-for="item in customerList" :value="item.id" :label="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="initUserList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div id="myChart" class="chart-part" style="margin-top:20px;height: 1000px"></div>
            </el-scrollbar>
            <div v-show="activeTab==2" class="flex-row-right flex-column">
                <el-form inline ref="searchForm" :model="searchParam" size="mini" style="flex: 1">
                    <el-form-item label="搜索" prop="content" >
                        <el-input placeholder="搜索聊天关键字" v-model="searchParam.content"></el-input>
                    </el-form-item>
                    <el-form-item label="用户openId" prop="externalUserid" >
                        <el-input placeholder="输入用户openId" v-model="searchParam.externalUserid"></el-input>
                    </el-form-item>
                    <el-form-item label="日期范围">
                        <el-date-picker
                            v-model="searchParam.date"
                            type="daterange"
                            :picker-options="pickerOptions"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            align="left"
                            unlink-panels
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="发送客服">
                        <customerServiceList :multipleType="1" :selectUsers="searchParam.qyWeixinUserid" @selectUsers="selectUsers"></customerServiceList>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" @click="submitSearch">搜索</el-button>
                    </el-form-item>
                </el-form>
                <el-scrollbar class="chat-search-wrap" v-loading="chatResultLoading">
                    <chatRecordSearch  :searchParam="searchParam" :chatResultList="chatResultList"></chatRecordSearch>
                </el-scrollbar>
            </div>
        </div>
    </defaultSec>

</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
import chatRecordSearch from "@c/scrm/publicComponent/chatRecordSearch";
import customerServiceList from "@c/scrm/publicComponent/customerServiceList";
//eChart 初始化
let chatHeight = 150
let chatTop = 80
let gridArr = []
let xAxis = []
let yAxis = []
let yAxisTitle = ['聊天总数','聊天总用户数','客服发送消息','用户发送消息']
let dataRoomArr = []
for(let i=0;i<4;i++){
    gridArr.push({
        top:chatTop*(i+1)+chatHeight*i,
        left:50,right:50,
        height:chatHeight
    })
    dataRoomArr.push({
        type: 'slider',
        show: true,
        filterMode: 'filter',
        xAxisIndex: i,
        start: 0,
        end: 60,
        height:16,
        top:(chatTop+chatHeight)*(i+1)+20
    })
    xAxis.push({
        type:'category',
        gridIndex: i
    })
    yAxis.push({
        gridIndex: i,
        name:yAxisTitle[i],
        nameTextStyle:{fontSize:16,fontWeight:'bolder'}
    })
}
let chartOption = {
    legend: {
        show:true,
        icon:'circle'
    },
    dataZoom: dataRoomArr,
    toolbox:{
        show:true,
        feature: {
            magicType: {
                type: ["line", "bar"]
            },
            restore: {},
            saveAsImage: {}
        },
    },
    grid: gridArr,
    xAxis,
    yAxis,
    tooltip: {
        trigger: 'item',
        axisPointer: {
            type: 'shadow'
        }
    },
    label: {
        show:true,
        position: 'top',
        formatter:'{a}\n{c}'
    },
    series: []
};
export default {
    name: "chatrecord",
    data(){
        return{
            activeTab:0,
            userId:'',
            myChart:{},
            userList:[],
            customerList:[],
            tableList:[],
            loading:false,
            total:0,
            pageSize:20,
            formParam:{
                qyWeixinUserids:'',
                qyWeixinUserid:[],
                currentPage:1,
                pageSize:100,
                externalUserid:'',
                sdate:'',
                edate:'',
                group:1,//1汇总，2明细
                byHour:0,//1按时，0按天
                date:[new Date().Format('yyyy-MM-dd'),new Date().Format('yyyy-MM-dd')]
            },
            tableParam:{
                qyWeixinUserids:'',
                qyWeixinUserid:[],
                currentPage:1,
                pageSize:20,
                sdate:'',
                edate:'',
                group:1,//1汇总，2明细
                byHour:0,//1按时，0按天
                date:[new Date().Format('yyyy-MM-dd'),new Date().Format('yyyy-MM-dd')]
            },
            searchParam:{
                qyWeixinUserids:'',
                qyWeixinUserid:[],
                currentPage:1,
                pageSize:20,
                sdate:'',
                edate:'',
                content:'',
                date:[new Date().Format('yyyy-MM-dd'),new Date().Format('yyyy-MM-dd')]
            },
            chatTotal:0,
            chatResultList:[],
            chatResultLoading:false,
            pickerOptions: {
                shortcuts: [{
                    text: '最近两天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }
        }
    },
    components: {defaultSec,tablePagination,chatRecordSearch,customerServiceList},
    mounted() {

        let myChart = this.$echarts.init(document.getElementById('myChart'))
        this.myChart = myChart

        this.initCustomerList()
        this.initUserList('init')
        window.addEventListener('resize', function() {
            myChart.resize();
        });
        myChart.setOption({
            ...chartOption
        })
        //搜索记录监听滚动
        this.resultScroll()

    },
    methods:{
        //标签切换
        tabChange(index){
            this.activeTab = index
        },
        //企微客服列表
        initCustomerList(){
            this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                console.log(res)
                this.customerList = res
            })
        },
        initUserList(type){
            this.myChart.showLoading();
            let {date,qyWeixinUserid,...params} = this.formParam
            params.sdate = date[0]
            params.edate = date[1]
            params.qyWeixinUserids = qyWeixinUserid.join(',')
            this.$apiGet('lechun-bi/generateScene/getQwDataList', {...params}).then(res => {
                console.log(res)
                this.userList = res.list
                if(type=='init'){
                    this.tableList = res.list
                }

                let obj = {}
                let objHour = {}
                let objUser = {}
                let xDataKey = 0
                let xData = []
                //按天汇总
                if(params.group==1&&params.byHour==0){
                    xDataKey = 0
                }
                //按天明细
                if(params.group==2&&params.byHour==0){
                    xDataKey = 'msgDate'
                }
                //按时汇总
                if(params.group==1&&params.byHour==1){
                    xDataKey = 'msgHour'
                }
                //按时明细
                if(params.group==2&&params.byHour==1){
                    xDataKey = 3
                }
                res.list.map(item=>{
                    if(xDataKey==3){
                        if(obj.hasOwnProperty(item.msgDate+' '+item.msgHour)){
                            obj[item.msgDate+' '+item.msgHour+'时'].push(item)
                        }else{
                            obj[item.msgDate+' '+item.msgHour+'时'] = [item]
                        }
                    }
                    if(xDataKey!==3&&xDataKey!==0){
                        let key = xDataKey=='msgHour'?item[xDataKey]+'时':item[xDataKey]
                        if(objHour.hasOwnProperty(key)){
                            obj[key].push(item)
                        }else{
                            obj[key] = [item]
                        }
                    }

                    if(objUser.hasOwnProperty(item.userName)){
                        objUser[item.userName]['chatCount'].push(item.chatCount)
                        objUser[item.userName]['chatUserCount'].push(item.chatUserCount)
                        objUser[item.userName]['qwToUserCount'].push(item.qwToUserCount)
                        objUser[item.userName]['UserToQwCount'].push(item.UserToQwCount)
                    }else{
                        objUser[item.userName] = {}
                        objUser[item.userName]['chatCount'] = [item.chatCount]
                        objUser[item.userName]['chatUserCount'] = [item.chatUserCount]
                        objUser[item.userName]['qwToUserCount'] = [item.qwToUserCount]
                        objUser[item.userName]['UserToQwCount'] = [item.UserToQwCount]
                    }
                })

                if(xDataKey==0){
                    xData = [params.sdate+' ~ '+params.edate]
                }else{
                    xData = Object.keys(obj)
                }
                let series = []
                for(let key in objUser){
                    series.push({
                        name:key,
                        type:'bar',
                        data:objUser[key].chatCount
                    },{
                        name:key,
                        type:'bar',
                        data:objUser[key].chatUserCount,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                    },{
                        name:key,
                        type:'bar',
                        data:objUser[key].qwToUserCount,
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                    },{
                        name:key,
                        type:'bar',
                        data:objUser[key].UserToQwCount,
                        xAxisIndex: 3,
                        yAxisIndex: 3,
                    })
                }
                console.log(objUser)
                console.log(xData,series)
                this.myChart.hideLoading();
                this.myChart.setOption({
                    ...chartOption,
                    xAxis: [{
                        data:xData,
                        type:'category',
                        gridIndex: 0
                    },{
                        data:xData,
                        type:'category',
                        gridIndex: 1
                    },{
                        data:xData,
                        type:'category',
                        gridIndex: 2
                    },{
                        data:xData,
                        type:'category',
                        gridIndex: 3
                    }],
                    series: series
                },true)

            })
        },
        initTableList(){
            this.loading = true
            let {date,qyWeixinUserid,...params} = this.tableParam
            params.sdate = date[0]
            params.edate = date[1]
            params.qyWeixinUserids = qyWeixinUserid.join(',')
            this.$apiGet('lechun-bi/generateScene/getQwDataList', {...params}).then(res => {
                this.loading = false
                this.tableList = res.list
            })
        },
        showDetail(item){
            console.log(item)
            let id = item.qyWeixinUserid
            this.$router.push({path:'/chatrecorddetail/:id',name:'chatRecordDetail',params:{id},query:{name:item.name}})
        },
        //搜索记录
        initChatResultList(from){
            this.chatResultLoading = true
            if(!from){
                this.searchParam.currentPage = 1
            }

            let {date,qyWeixinUserid,...params} = this.searchParam
            params.sdate = date[0]
            params.edate = date[1]
            let ids = qyWeixinUserid.map(item=>{return item.qyWeixinUserId})
            params.qyWeixinUserid = ids.join(',')
            this.$apiGet('lechun-bi/generateScene/getQwChatListForSearch', {...params}).then(res => {
                if(from=='scroll'){
                    this.chatResultList = [...this.chatResultList,...res.list]
                }else{
                    this.chatResultList = res.list
                }
                this.chatTotal = res.total
                this.chatResultLoading = false
            })
        },
        submitSearch(){
            this.$refs['searchForm'].validate((valid) => {
                if (valid) {
                    this.initChatResultList()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        //搜索客服选择
        selectUsers(val){
            this.searchParam.qyWeixinUserid = val.value
        },
        resultScroll(){
            //监听滚动
            let dom = document.querySelector('.chat-search-wrap .el-scrollbar__wrap')
            dom.scrollTop = 0
            dom.addEventListener('scroll',()=>{
                let scrollTop = dom.scrollTop
                let clientHeight = dom.clientHeight
                let scrollHeight = dom.scrollHeight
                console.log(scrollTop+clientHeight,scrollHeight)
                if(scrollTop+clientHeight>=scrollHeight){
                    console.log(scrollTop)
                    if(this.chatResultList.length<this.chatTotal){
                        this.leftLoading = true
                        setTimeout(()=>{
                            this.searchParam.currentPage++
                            this.initChatResultList('scroll')
                        },1000)

                    }
                }
            })
            //加载数据销毁滚动
            if(this.chatResultLoading){
                console.log('leftd')
                dom.removeEventListener('scroll',()=>{})
            }
        },
    }
}
</script>

<style  lang="scss" rel="stylesheet/scss">
.flex-row-wrap{
    display: flex;
    height: 100%;
    .flex-row-left{
        width: 100px;
        border-radius: 5px 0 0 5px;
        height: calc(100% - 20px);
        padding: 10px 0;
        background: #f5f5f5;
        font-size: 12px;
        box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);
        position: relative;
        z-index: 9;
    }

    .flex-row-right{
        flex: 1;
        height: calc(100% - 20px);
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        padding: 10px 10px 10px 15px;
    }
    .flex-column{
        display: flex;
        flex-direction: column;
    }
}
.chat-record{
    .left-tab{
        position: relative;
        font-size: 14px;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;

    }
    .left-tab-on{
        font-size: 16px;
        position: relative;
        color: #209e91;
        background: rgba(32,158,145,.1);
        font-weight: bolder;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 3px;
            height: 100%;
            background: #209e91;
        }
    }
    .chat-search-wrap{
        //height:calc(100% - 100px)
    }
}

</style>